<template>
  <b-card>
    <!-- form -->
    <b-form>
      <b-row>
        <!-- old password -->
        <b-col md="12">
          <b-form-group
            :label="$t('Old Password')"
            label-for="account-old-password"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-old-password"
                v-model="passwordValueOld"
                @blur="$v.passwordValueOld.$touch()"
                name="old-password"
                :type="passwordFieldTypeOld"
                :placeholder="$t('Old Password')"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconOld"
                  class="cursor-pointer"
                  @click="togglePasswordOld"
                />
              </b-input-group-append>
            </b-input-group>
             <small 
              v-if="isSubmit && !$v.passwordValueOld.required"
              class="form-text text-danger">
                  {{$t("This field is required")}}
             </small>
          </b-form-group>
        </b-col>
        <!--/ old password -->
      </b-row>
      <b-row>
        <!-- new password -->
        <b-col md="12">
          <b-form-group
            label-for="account-new-password"
            :label="$t('New Password')"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-new-password"
                v-model="newPasswordValue"
                @blur="$v.newPasswordValue.$touch()"
                :type="passwordFieldTypeNew"
                name="new-password"
                :placeholder="$t('New Password')"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconNew"
                  class="cursor-pointer"
                  @click="togglePasswordNew"
                />
              </b-input-group-append>
            </b-input-group>
             <small 
              v-if="isSubmit && !$v.newPasswordValue.required"
              class="form-text text-danger">
                  {{$t("This field is required")}}
              </small>
              <small 
              v-if="isSubmit && !$v.newPasswordValue.minLength"
              class="form-text text-danger">
                  {{$t("Password must be at least 8 characters")}}
              </small>
              <small 
              v-if="isSubmit && !$v.newPasswordValue.specialCharAndNumber"
              class="form-text text-danger">
                  {{$t("Use Special Characters And Numbers")}}
              </small>
          </b-form-group>
        </b-col>
        <!--/ new password -->

        <!-- retype password -->
        <b-col md="12">
          <b-form-group
            label-for="account-retype-new-password"
            :label="$t('Retype New Password')"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-retype-new-password"
                v-model="RetypePassword"
                @blur="$v.RetypePassword.$touch()"
                :type="passwordFieldTypeRetype"
                name="retype-password"
                :placeholder="$t('New Password')"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconRetype"
                  class="cursor-pointer"
                  @click="togglePasswordRetype"
                />
              </b-input-group-append>
            </b-input-group>
             <small 
              v-if="isSubmit && !$v.RetypePassword.required"
              class="form-text text-danger">
                  {{$t("This field is required")}}
              </small>
              <small
              v-if="isSubmit && !$v.RetypePassword.sameAs"
              class="form-text text-danger d-block">
                {{ $t("Does not match the password") }}
              </small>
          </b-form-group>
        </b-col>
        <!--/ retype password -->

        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click="passwordUpdate" 
          >
            {{$t('Save Changes')}}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            class="mt-1"
          >
            {{$t('Reset')}}
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required, sameAs, minLength } from "vuelidate/lib/validators";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      isSubmit: false
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    passwordUpdate(){
      if(this.$v.$invalid === false) {
        this.$database.AccountService.change_password({
          OldPassword: this.passwordValueOld,
          NewPassword: this.newPasswordValue
        }).then(res => {
          console.log(res);
          if(res.IsSuccess === true || res.IsSuccess === 1){
            this.$functions.Messages.success('Password Update Successful')
            //işlem bittikten sonra değerleri sıfırla, isSubmit false çek
            this.passwordValueOld = ''
            this.newPasswordValue = ''
            this.RetypePassword = ''
            this.isSubmit = false
          } else {
            this.$functions.Messages.error('Password Update Failed')
          }
        })
        // console.log('calisti');
      } else {
        this.isSubmit = true
      }
    },
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
  },
  validations: {
    passwordValueOld: {
      required
    },
    newPasswordValue: {
      required,
      minLength: minLength(8),
      specialCharAndNumber: (value) => {
        let re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        return re.test(value) ? true : false;
      }
    },
    RetypePassword: {
      required,
      sameAs: sameAs((vm) => {
        return vm.newPasswordValue;
      })
    }

        // passwordValueOld: '',
      // newPasswordValue: '',
      // RetypePassword: '',
  }
}
</script>

<style scoped>
input{
  min-width: 80% !important;
}
</style>