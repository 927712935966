<template>
  <b-card>
    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="12">
          <b-form-group
            :label="$t('Name')"
            label-for="account-username"
          >
            <b-form-input
              v-model="userInfo.name"
              :placeholder="$t('Name')"
              name="Name"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group
            :label="$t('Surname')"
            label-for="account-name"
          >
            <b-form-input
              v-model="userInfo.last_name"
              name="Surname"
              :placeholder="$t('Surname')"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group
            :label="$t('Email')"
            label-for="account-e-mail"
          >
            <b-form-input
              disabled
              v-model="userInfo.email"
              name="email"
              :placeholder="$t('Email')"
            />

          </b-form-group>
        </b-col>

        <!-- alert -->
        <b-col
          cols="12"
          class="mt-75"
        >
        </b-col>
        <!--/ alert -->

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 d-flex float-right"
            @click="saveChanges()"
          >
            {{$t('Save Changes')}}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      profileFile: null,
      userInfo: {}
    }
  },
  methods: {
    saveChanges(){
      this.$database.AccountService.update_name_lastname_dropdown(
        this.$store.getters["auth/userInfo"].person_id,
        this.$store.getters["auth/userInfo"].email,
        this.$store.getters["auth/userInfo"].company_branch_id,
        this.userInfo.name,
        this.userInfo.last_name
      ).then(res => {
        if(res.is_success === true || res.is_success === 1){
          sessionStorage.setItem('auth/userInfo',JSON.stringify(this.$store.getters["auth/userInfo"]))
          this.$functions.Messages.success('Successfully edited!')
        }
      })
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
  created(){
    this.userInfo = this.$store.getters["auth/userInfo"]
    console.log(this.userInfo);
  }
}
</script>
